.hover-text-underlined {
  position: relative; }
  .hover-text-underlined::before {
    position: absolute;
    bottom: -3px;
    left: 0;
    content: '';
    width: 100%;
    height: 1px;
    transform: scaleX(0);
    transform-origin: right top;
    transition: transform .3s;
    background: var(--color-white); }
  .hover-text-underlined:hover::before {
    transform-origin: left top;
    transform: scaleX(1); }

.transform-left {
  transition: 0.5s;
  transform: translateX(-4vw) !important; }

.hidden {
  opacity: 0;
  visibility: hidden; }

.show {
  opacity: 1;
  visibility: visibility; }

.overflow-hidden {
  overflow-y: hidden; }

.no-trans {
  transform: none !important; }

.img-scroll img {
  width: 100%;
  height: auto;
  max-width: 100%;
  transition: all 2s;
  animation-duration: 25s;
  animation-name: autoScroll;
  animation-iteration-count: 1000; }

.text-ogg {
  font-family: Ogg, "Cormorant Upright", serif; }

.placeholder {
  width: 100px !important;
  height: 200px !important;
  margin: 0 auto; }

@keyframes lightclick {
  0% {
    transform: scale(0);
    opacity: 0; }
  100% {
    transform: scale(50);
    opacity: 1; } }

@keyframes opacity {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes closeMenu {
  0% {
    opacity: 0;
    transform: translateY(-4vw); }
  100% {
    opacity: 1;
    transform: translateY(0); } }

@keyframes animationLoadRouter {
  0% {
    transform: translateY(100%); }
  30% {
    transform: translateY(0); }
  70% {
    transform: translateY(100%); }
  100% {
    transform: translateY(100%); } }

@keyframes loader1 {
  0% {
    opacity: 0;
    stroke-dasharray: 150; }
  30% {
    opacity: 10;
    stroke-dasharray: 150; }
  90% { }
  100% {
    opacity: 0;
    stroke-dasharray: 0; } }

@keyframes loader2 {
  0% {
    opacity: 0;
    stroke-dasharray: 500; }
  30% {
    opacity: 10;
    stroke-dasharray: 500; }
  90% { }
  100% {
    opacity: 0;
    stroke-dasharray: 0; } }

@keyframes scaleTrans {
  0% {
    transform: scale(0.9); }
  100% {
    transform: scale(1.2); } }

@keyframes elasticus {
  0% {
    transform: scale(1, 0);
    transform-origin: 0% 0%; }
  50% {
    transform: scale(1, 1);
    transform-origin: 0% 0%; }
  50.1% {
    transform: scale(1, 1);
    transform-origin: 0% 100%; }
  100% {
    transform: scale(1, 0);
    transform-origin: 0% 100%; } }

@keyframes rotate {
  from {
    transform: rotate(360deg); }
  to {
    transform: rotate(0); } }

@keyframes autoScroll {
  0% {
    transform: translateY(0%); }
  50% {
    transform: translateY(-80%); }
  100% {
    transform: translateY(0%); } }

/*-------------------------- 
      Global Styles
---------------------------*/
html {
  font-size: 62.5%;
  cursor: none; }

body {
  position: relative;
  font-family: 'Poppins', sans-serif;
  font-size: 1vw !important;
  color: #ffffff;
  overflow-x: hidden;
  width: 100%;
  height: 100%;
  background: var(--color-dark); }

.huy {
  color: red; }

section {
  position: relative;
  transition: 0.5s; }

.wrapper {
  position: relative;
  display: block;
  background: var(--color-dark);
  margin: auto;
  transition: opacity 1s ease 1s;
  top: 0;
  left: 0; }

img {
  max-width: 100%;
  width: 100%; }

::-webkit-scrollbar {
  width: 0px !important;
  display: none !important; }

.backgroundBlur {
  background: #00000091;
  height: 100%;
  left: 0;
  position: fixed;
  right: o;
  top: 0;
  width: 100%;
  z-index: 100;
  transition: 0.5s; }

/*-------------------------
     RESET Default Styles
 --------------------------*/
* {
  outline: none; }

button {
  border: none;
  padding: 0; }

button,
button:focus,
.form-control,
.form-control:focus {
  outline: none; }

textarea {
  resize: none; }

select {
  background-color: transparent; }

a {
  color: var(--color-white) !important;
  text-decoration: none !important; }

a:hover {
  text-decoration: none !important;
  cursor: inherit; }

h1, h2, h3 {
  font-family: Ogg, "Cormorant Upright", serif;
  margin: 0; }

@media (min-width: 1400px) {
  .container {
    max-width: 1140px !important; } }

@media (max-width: 1200px) {
  body {
    font-size: 2.5vw !important; }
    body .cursor, body .follower {
      display: none; }
    body .wrapper {
      height: 100%; } }

@media (min-width: 768px) and (max-width: 1200px) {
  body {
    font-size: 2.5vw !important; } }

/*----------------------------
      Helper Classes
----------------------------*/
/*-------------------------
   Background & Colors
--------------------------*/
/* Colors */
/*--------------------
   Buttons
---------------------*/
.btn {
  text-transform: capitalize;
  position: relative;
  z-index: 1; }
  .btn:focus, .btn.active, .btn:active {
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    outline: none; }

/*-------------------------
   Font Size & Font Weight
--------------------------*/
@font-face {
  font-family: Ogg;
  src: url("../../asset/fonts/Ogg-Roman.otf") format("woff2"), url("../../asset/fonts/Ogg-Roman.otf") format("woff");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: "Cormorant Upright";
  src: url("../../asset/fonts/CormorantUpright-Regular.ttf") format("woff2"), url("../../asset/fonts/CormorantUpright-Regular.ttf") format("woff");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: "vladimir";
  src: url("./../fonts/vladimir.TTF") format("woff2"), url("./../fonts/vladimir.TTF") format("woff");
  font-weight: 400;
  font-style: normal; }

/* Font Size */
.font--80 {
  font-size: 8rem; }

.font--20 {
  font-size: 20px; }

/* Font Weight */
/*==================== HEADER ====================*/
.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  margin-top: 4vw;
  padding: 0 7vw 0 7vw;
  color: var(--color-white);
  font-weight: 400;
  visibility: hidden;
  z-index: 98; }
  .header .header-logo {
    position: absolute;
    top: 0;
    left: 0; }
    .header .header-logo__img {
      width: 1.953vw; }
      .header .header-logo__img:hover {
        transform: scale(1.1); }
    .header .header-logo__text {
      font-weight: 500;
      margin-left: 1vw; }
    .header .header-logo__before {
      position: absolute;
      top: -28px;
      left: -33px;
      width: 100px;
      height: 100px;
      border-radius: 100%;
      opacity: 1;
      transition: 0.8s ease-in-out;
      transform: scale(0);
      pointer-events: none;
      background: var(--color-light); }
    .header .header-logo .light-click {
      transform: scale(50);
      transition: 0.8s ease-in-out; }
  .header .header-project {
    cursor: pointer;
    position: absolute;
    top: 1vw;
    right: 0; }

@media (max-width: 1200px) {
  .header .header-logo__img {
    width: 4.5vw; }
  .header .header-logo__text {
    margin-left: 2vw; } }

/*==================== FOOTER ====================*/
.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  margin-bottom: 4vw;
  padding: 0 7vw 0 7vw;
  color: var(--color-white);
  font-weight: 400;
  z-index: 98;
  visibility: hidden; }
  .footer .footer-socials {
    transition: 0.5s;
    position: absolute;
    bottom: 0;
    left: 0; }
    .footer .footer-socials div i {
      font-size: 1.4vw; }
    .footer .footer-socials div + div {
      margin-top: 1vw; }
  .footer .footer-year {
    position: absolute;
    bottom: 0;
    right: 0; }

@media (max-width: 1200px) {
  .footer .footer-socials div i {
    font-size: 5vw; }
  .footer .footer-socials div + div {
    margin-top: 2vw; } }

/*==================== MORE ====================*/
.more {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 99;
  height: 100%;
  width: 100%;
  margin: auto;
  padding: 4vw 7vw;
  pointer-events: none;
  visibility: hidden; }
  .more__div {
    position: relative;
    height: 100%; }
    .more__div .brand {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 4vw;
      height: 4vw;
      top: 8vw;
      right: 15vw;
      pointer-events: visible;
      opacity: 0.15;
      transition: 0.2s; }
      .more__div .brand .icon-circle {
        width: 4vw;
        height: 4vw;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center; }
        .more__div .brand .icon-circle__child {
          width: 3vw;
          height: 3vw;
          border-radius: 50%;
          display: flex;
          border: 1px solid var(--color-white);
          justify-content: center;
          align-items: center; }
          .more__div .brand .icon-circle__child .svg-play {
            margin-left: 0.1vw; }
          .more__div .brand .icon-circle__child svg {
            width: 0.9vw;
            font-size: 7px;
            fill: var(--color-white); }
      .more__div .brand .brand-label {
        transition: all 0.2s ease-in-out; }
        .more__div .brand .brand-label svg {
          position: absolute;
          left: -90%;
          top: -90%;
          height: 280%;
          width: 280%;
          pointer-events: none;
          animation-name: rotate;
          animation-duration: 15s;
          animation-iteration-count: infinite;
          animation-timing-function: linear;
          animation-play-state: paused; }
          .more__div .brand .brand-label svg text {
            font-family: "Inter", sans-serif;
            font-size: 19px;
            letter-spacing: 2.2px;
            font-variation-settings: "wght" 650;
            fill: var(--color-white);
            text-transform: uppercase;
            transition: 0.5s; }
      .more__div .brand:hover .brand-label svg {
        animation-play-state: running; }
  .more .svgMore-active {
    animation-play-state: running !important; }
  .more .svgMore-opacity {
    opacity: 1 !important; }

@media (max-width: 1200px) {
  .more__div .brand {
    width: 8vw;
    height: 8vw;
    top: 40vw; }
    .more__div .brand .icon-circle {
      width: 6vw;
      height: 6vw; }
      .more__div .brand .icon-circle__child {
        width: 6vw;
        height: 6vw; }
        .more__div .brand .icon-circle__child svg {
          width: 1.8vw; }
    .more__div .brand .brand-label svg text {
      font-size: 19px;
      letter-spacing: 2.2px; } }

/*==================== NOISE ====================*/
.noise::before {
  position: fixed;
  top: 0;
  left: -1px;
  width: 100vw;
  height: 100vh;
  content: "";
  opacity: .03;
  z-index: 1000;
  pointer-events: none;
  background-image: url("../../asset/image/noise.gif"); }

/*==================== CURSOR ====================*/
.hover-cursor {
  transition: 0.5s;
  cursor: none !important; }
  .hover-cursor:hover ~ .cursor {
    transform: translate(-50%, -50%) scale(8); }

.hover-cursor-trans {
  transition: 1s;
  cursor: none !important; }
  .hover-cursor-trans:hover ~ .cursor {
    transform: translate(-50%, -50%) scale(8); }

.cursor, .follower {
  will-change: transform;
  position: fixed;
  bottom: 0;
  left: 0;
  pointer-events: none;
  border-radius: 50%;
  mix-blend-mode: difference;
  -webkit-transform: scale(1);
  transform: scale(1); }

.cursor {
  width: 8px;
  height: 8px;
  z-index: 999;
  background-color: #ffffff; }

.cursor.is-active {
  -webkit-transform: scale(10);
  transform: scale(10);
  opacity: 0; }

.cursor.is-product {
  -webkit-transform: scale(10);
  transform: scale(10);
  opacity: 0; }

.cursor.is-img {
  -webkit-transform: scale(10);
  transform: scale(10);
  opacity: 0; }

.follower {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  border: 1px solid #ffffff;
  z-index: 998;
  transition: transform .3s; }
  .follower__text {
    opacity: 0; }

.follower.is-active {
  background-color: #ffffff;
  -webkit-transform: scale(1.6);
  transform: scale(1.6); }

.follower.is-img {
  width: 39vw;
  height: 23vw;
  border: none;
  border-radius: 0;
  background: url("../../asset/image/icons/load.gif") no-repeat center;
  background-size: 100%;
  mix-blend-mode: unset !important; }

.follower.is-product {
  mix-blend-mode: unset;
  background-color: #ffffffd3;
  -webkit-transform: scale(2);
  transform: scale(2);
  border: none; }
  .follower.is-product .follower__text {
    opacity: 1;
    font-family: 'Poppins', sans-serif;
    font-weight: 100;
    font-size: 0.6vw !important;
    letter-spacing: 0.1vw;
    color: #000000;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center; }

@media (max-width: 1200px) {
  .hover-cursor-trans, .hover-cursor, .hover-text, .hover-img {
    transform: none !important; } }

/*==================== MENU ====================*/
.menu {
  position: fixed;
  top: 0;
  right: -40vw;
  width: 28vw;
  height: 100%;
  background: var(--color-light);
  z-index: 102;
  transition: 0.5s; }
  .menu .menuProducts {
    height: 100%;
    padding: 2.3vw 2vw;
    overflow-x: scroll; }
    .menu .menuProducts .container {
      padding: 0; }
      .menu .menuProducts .container .menuProduct {
        cursor: none;
        position: relative;
        margin-top: 4vw; }
        .menu .menuProducts .container .menuProduct__img {
          width: 100%;
          height: 15vw;
          overflow: hidden; }
          .menu .menuProducts .container .menuProduct__img img {
            height: 100%;
            -o-object-fit: cover;
            object-fit: cover;
            image-rendering: -webkit-optimize-contrast; }
          .menu .menuProducts .container .menuProduct__img:hover {
            filter: contrast(0.6);
            transition: 0.5s; }
        .menu .menuProducts .container .menuProduct__name {
          position: absolute;
          bottom: -2.3vw;
          right: 0; }
          .menu .menuProducts .container .menuProduct__name h2 {
            font-family: 'vladimir', sans-serif;
            font-size: 3vw;
            color: var(--color-dark);
            transition: 0.5s;
            text-transform: capitalize; }
      .menu .menuProducts .container .menuProduct-first {
        margin-top: 0 !important; }
  .menu__close {
    position: absolute;
    width: 4vw;
    height: 4vw;
    top: 3.5vw;
    left: -6vw;
    border: 1.5px solid var(--color-light);
    border-radius: 50%;
    z-index: 101;
    opacity: 0;
    transition: 0.5s;
    transform: translateY(-500); }
    .menu__close img {
      width: 50%; }

.menutranfrom {
  right: 0 !important; }

.arrowDark {
  filter: invert(1); }

@media (min-width: 768px) and (max-width: 1200px) {
  .menu {
    width: 50vw;
    right: -70vw; }
    .menu__close {
      width: 8vw;
      height: 8vw;
      top: 2.5vw;
      left: -12vw;
      border: 2px solid var(--color-light); }
    .menu .menuProducts {
      padding: 4vw 3vw; }
      .menu .menuProducts .container .menuProduct {
        margin-top: 6vw; }
        .menu .menuProducts .container .menuProduct__img {
          height: 27vw; }
        .menu .menuProducts .container .menuProduct__name {
          bottom: -4vw; }
          .menu .menuProducts .container .menuProduct__name h2 {
            font-size: 6vw; } }

@media (max-width: 768px) {
  .menu {
    width: 100vw;
    right: -100vw;
    transition: 0.3s; }
    .menu__close {
      width: 10vw;
      height: 10vw;
      border: 1px solid var(--color-dark);
      top: 2vw;
      left: 50%;
      transform: translateX(-50%) !important; }
      .menu__close img {
        filter: invert(1); }
    .menu .menuProducts {
      padding: 15vw 3vw 4vw 3vw; }
      .menu .menuProducts .container .menuProduct {
        margin-top: 15vw; }
        .menu .menuProducts .container .menuProduct__img {
          height: 50vw; }
        .menu .menuProducts .container .menuProduct__name {
          bottom: -8vw; }
          .menu .menuProducts .container .menuProduct__name h2 {
            font-size: 10vw; }
  .arrowDark {
    filter: invert(0) !important; } }

/*==================== PRELOADER ====================*/
#preloader {
  position: absolute;
  display: flex !important;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  z-index: 999;
  pointer-events: none;
  background-color: var(--color-dark);
  flex-direction: column; }
  #preloader svg {
    width: 7vw; }
    #preloader svg .st0 {
      fill: none;
      stroke: #f1f505;
      stroke-width: 2;
      stroke-miterlimit: 10; }
    #preloader svg path:first-child {
      stroke: #fff;
      fill: none;
      stroke-dasharray: 150;
      opacity: 10;
      transition: 0.5; }
    #preloader svg path:last-child {
      stroke: #fff;
      fill: none;
      stroke-dasharray: 500;
      opacity: 10;
      transition: 0.5; }
  #preloader #percent {
    margin-top: 1.5vw;
    color: var(--color-white);
    font-size: 1vw; }

@media (max-width: 1200px) {
  #preloader svg {
    width: 15vw; }
  #preloader #percent {
    margin-top: 3.5vw;
    font-size: 3vw; } }

/*==================== LOADING ====================*/
.loading {
  position: fixed;
  top: 0;
  left: 0;
  pointer-events: none;
  z-index: 100;
  width: 100%;
  height: 100vh;
  background-color: var(--color-white);
  transition: 0.8s; }
  .loading .page-transition {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%; }

.loadRouter {
  position: fixed;
  top: 0;
  left: 0;
  pointer-events: none;
  z-index: 100;
  width: 100%;
  height: 100vh;
  background-color: var(--color-light);
  transform: translateY(100%);
  transition: 0.8s; }

.animationLoadRouter {
  animation: animationLoadRouter 3s ease; }

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  pointer-events: none;
  z-index: 99;
  width: 100%;
  height: 100vh;
  background-color: var(--color-dark); }

/*==================== HOME ====================*/
.home {
  padding: 35vh 0 35vh 0;
  width: 100vw;
  transition: 0.5s;
  opacity: 0; }
  .home .home-text {
    width: 65%;
    margin: auto;
    color: var(--color-white); }
    .home .home-text h2 {
      position: relative;
      word-break: break-all;
      font-size: 5vw;
      line-height: 7vw;
      cursor: pointer; }
      .home .home-text h2 a {
        margin: 0; }

@media (max-width: 1200px) {
  .home {
    height: 100%;
    padding: 35vh 0; }
    .home .home-text h2 {
      font-size: 7vw;
      line-height: 10vw; } }

/*==================== ABOUT ME ====================*/
.about {
  color: var(--color-white);
  opacity: 0;
  transition: 0.5s; }
  .about__title h2 {
    font-size: 5vw;
    letter-spacing: 0.5vw; }
  .about .container {
    padding: 18vw 0 60vh 0;
    width: 70%;
    max-width: 70% !important; }
    .about .container h3 {
      font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
      font-weight: 400; }
    .about .container h3 + h3 {
      margin-top: 0.7vw; }
    .about .container p {
      margin-bottom: 0 !important;
      font-size: 0.93vw;
      font-weight: 400; }
    .about .container .title-about {
      position: relative;
      display: inline-block; }
      .about .container .title-about h2 {
        font-size: 2.34vw; }
      .about .container .title-about::before {
        position: absolute;
        bottom: -0.05vw;
        left: 0;
        content: '';
        width: 100%;
        height: 0.156vw;
        transform: scaleX(0.4);
        transform-origin: left top;
        transition: transform .3s;
        background: var(--color-white); }
      .about .container .title-about:hover::before {
        transform-origin: left top;
        transform: scaleX(1); }
    .about .container .about-div {
      margin-top: 4vw; }
    .about .container .about-content {
      margin-top: 2vw; }
    .about .container .about-info {
      position: relative;
      margin-top: 4vw; }
      .about .container .about-info .scroll-down {
        bottom: 0;
        height: 6.406vw;
        position: absolute;
        right: 0;
        text-align: center;
        z-index: 1; }
        .about .container .about-info .scroll-down .scroll-down-text {
          color: var(--color-white);
          font-size: 0.7vw; }
        .about .container .about-info .scroll-down .scroll-down-bar {
          animation: elasticus 1.2s cubic-bezier(1, 0, 0, 1) infinite;
          background: var(--color-white);
          bottom: 0;
          display: inline-block;
          height: 4.078vw;
          left: 0;
          margin: 0 auto;
          position: absolute;
          right: 0;
          width: 0.078vw; }
      .about .container .about-info__hello p {
        font-size: 1.1vw; }
      .about .container .about-info__hello h3 {
        margin-top: 1.17vw; }
      .about .container .about-info__content {
        margin-top: 1.56vw;
        width: 70%; }
        .about .container .about-info__content h3 p {
          line-height: 1.7vw; }
      .about .container .about-info__button {
        position: relative;
        margin-top: 4vw;
        width: 14vw;
        height: 3vw;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: 0.5s;
        background: var(--color-dark);
        border: 0.1vw solid var(--color-white); }
        .about .container .about-info__button i {
          margin-right: 0.78vw; }
        .about .container .about-info__button::before {
          content: "";
          position: absolute;
          width: 100%;
          height: 100%;
          border-left: 0.1vw solid var(--color-white);
          border-bottom: 0.1vw solid var(--color-white);
          opacity: 0;
          top: 0;
          right: 0;
          transition: 0.5s; }
        .about .container .about-info__button:hover {
          transform: translate(0.78vw, -0.78vw); }
          .about .container .about-info__button:hover::before {
            opacity: 1;
            top: 0.78vw;
            right: 0.78vw; }
    .about .container .about-experiences .about-experience {
      margin-top: 7vw; }
      .about .container .about-experiences .about-experience .experience {
        position: relative;
        padding: 3.125vw 0 5vw 7.81vw; }
        .about .container .about-experiences .about-experience .experience__line {
          position: absolute;
          width: 0.12vw;
          height: 100%;
          top: 0;
          left: 17.95vw;
          background: var(--color-light);
          z-index: 1; }
        .about .container .about-experiences .about-experience .experience__date {
          font-size: 0.78vw;
          width: 7.42vw; }
        .about .container .about-experiences .about-experience .experience__span {
          position: relative;
          width: 1.64vw;
          height: 1.64vw;
          border-radius: 50%;
          background: var(--color-white);
          z-index: 2;
          margin: 0 1.953vw;
          animation: scaleTrans 500ms alternate infinite ease-in; }
          .about .container .about-experiences .about-experience .experience__span::before {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 2.11vw;
            height: 2.11vw;
            border-radius: 50%;
            border: 0.078vw solid var(--color-white); }
        .about .container .about-experiences .about-experience .experience .experience-company {
          width: 50%; }
          .about .container .about-experiences .about-experience .experience .experience-company__name h3 {
            font-size: 1.09vw;
            font-weight: 700; }
          .about .container .about-experiences .about-experience .experience .experience-company__detail {
            margin-top: 1vw; }
            .about .container .about-experiences .about-experience .experience .experience-company__detail p {
              font-size: 0.94vw; }
            .about .container .about-experiences .about-experience .experience .experience-company__detail p + p {
              margin-top: 0.2vw; }
    .about .container .about-contacts h3 p a {
      text-decoration: underline !important; }

@media (max-width: 1200px) {
  .about__title h2 {
    font-size: 8vw; }
  .about .container {
    padding: 50vw 0 40vh 0;
    width: 65%;
    max-width: 65% !important; }
    .about .container h3 + h3 {
      margin-top: 1.5vw; }
    .about .container p {
      font-size: 1.7vw; }
    .about .container .title-about h2 {
      font-size: 4vw; }
    .about .container .about-div {
      margin-top: 7vw; }
    .about .container .about-content {
      margin-top: 3vw; }
    .about .container .about-info {
      margin-top: 4vw; }
      .about .container .about-info .scroll-down .scroll-down-text {
        font-size: 1.2vw; }
      .about .container .about-info__hello p {
        font-size: 2.1vw; }
      .about .container .about-info__hello h3 {
        margin-top: 1.17vw; }
      .about .container .about-info__content {
        margin-top: 1.56vw;
        width: 100%; }
        .about .container .about-info__content h3 p {
          line-height: 3vw; }
      .about .container .about-info__button {
        width: 20vw;
        height: 5vw;
        font-size: 1.5vw; }
        .about .container .about-info__button i {
          margin-right: 1.5vw; }
    .about .container .about-experiences .about-experience {
      margin-top: 7vw; }
      .about .container .about-experiences .about-experience .experience {
        padding: 3.125vw 0 5vw 7.81vw; }
        .about .container .about-experiences .about-experience .experience__line {
          left: 19vw; }
        .about .container .about-experiences .about-experience .experience__date {
          font-size: 1.2vw;
          width: 7vw; }
        .about .container .about-experiences .about-experience .experience__span {
          width: 2.5vw;
          height: 2.5vw;
          margin: 0 3vw; }
          .about .container .about-experiences .about-experience .experience__span::before {
            width: 3.5vw;
            height: 3.5vw; }
        .about .container .about-experiences .about-experience .experience .experience-company {
          width: 65%; }
          .about .container .about-experiences .about-experience .experience .experience-company__name h3 {
            font-size: 1.8vw;
            margin-top: 0vw;
            line-height: 3vw; }
          .about .container .about-experiences .about-experience .experience .experience-company__detail {
            margin-top: 1.5vw; }
            .about .container .about-experiences .about-experience .experience .experience-company__detail p {
              font-size: 1.5vw; }
            .about .container .about-experiences .about-experience .experience .experience-company__detail p + p {
              margin-top: 0.5vw; }
    .about .container .about-contacts h3 p a {
      text-decoration: underline !important; } }

@media (max-width: 400px) {
  .about .container {
    padding: 60vw 0 40vh 0; } }

/*==================== PRODUCT ====================*/
.productPage {
  color: var(--color-white);
  opacity: 0;
  transition: 0.5s; }
  .productPage__title {
    position: relative; }
    .productPage__title h2 {
      font-size: 5vw;
      letter-spacing: 0.5vw;
      margin-bottom: 0.5vw; }
    .productPage__title p {
      font-size: .875em;
      letter-spacing: 1vw;
      margin-left: 1vw; }
    .productPage__title .scroll-down {
      height: 6.406vw;
      position: absolute;
      bottom: -8vw;
      left: 50%;
      transform: translateX(-50%);
      text-align: center;
      z-index: 1; }
      .productPage__title .scroll-down .scroll-down-text {
        color: var(--color-white);
        font-size: 0.7vw; }
      .productPage__title .scroll-down .scroll-down-bar {
        animation: elasticus 1.2s cubic-bezier(1, 0, 0, 1) infinite;
        background: var(--color-white);
        bottom: 0;
        display: inline-block;
        height: 4.078vw;
        left: 0;
        margin: 0 auto;
        position: absolute;
        right: 0;
        width: 0.078vw; }
  .productPage .container {
    position: relative;
    padding: 40vh 0 80vh 0;
    width: 70%;
    max-width: 70% !important; }
    .productPage .container .productPage-video__iframe {
      transition: 0.5s;
      height: 39vw; }
    .productPage .container .productPage-view {
      position: relative;
      width: 100%;
      margin-top: 5vw; }
      .productPage .container .productPage-view .productPage-info {
        position: absolute;
        z-index: 5;
        left: 0;
        top: 3vw;
        width: 55%;
        mix-blend-mode: difference;
        color: #ffffff; }
        .productPage .container .productPage-view .productPage-info .productPage-decription__title h3 {
          font-size: 1.8vw; }
        .productPage .container .productPage-view .productPage-info .productPage-decription__content {
          margin-top: 1.17vw;
          text-align: justify; }
          .productPage .container .productPage-view .productPage-info .productPage-decription__content h4 {
            color: #eaeaea;
            font-size: 0.9375vw;
            line-height: 1.56vw; }
        .productPage .container .productPage-view .productPage-info .productPage-more {
          margin-top: 0.78vw; }
          .productPage .container .productPage-view .productPage-info .productPage-more .productPage-details {
            margin-right: 4vw;
            width: 50%; }
            .productPage .container .productPage-view .productPage-info .productPage-more .productPage-details .productPage-detail {
              margin-top: 1.56vw; }
              .productPage .container .productPage-view .productPage-info .productPage-more .productPage-details .productPage-detail__title {
                font-size: 1.1718vw; }
              .productPage .container .productPage-view .productPage-info .productPage-more .productPage-details .productPage-detail__content {
                margin-top: 0.156vw;
                color: #eaeaea;
                font-size: 0.9375vw;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis; }
                .productPage .container .productPage-view .productPage-info .productPage-more .productPage-details .productPage-detail__content a {
                  color: #eaeaea !important; }
                  .productPage .container .productPage-view .productPage-info .productPage-more .productPage-details .productPage-detail__content a svg {
                    width: 1.328vw;
                    margin-left: 0.78vw;
                    transition: 0.5s; }
                  .productPage .container .productPage-view .productPage-info .productPage-more .productPage-details .productPage-detail__content a:hover {
                    text-decoration: underline !important; }
                    .productPage .container .productPage-view .productPage-info .productPage-more .productPage-details .productPage-detail__content a:hover svg {
                      margin-left: 1.5625vw; }
      .productPage .container .productPage-view .productPage-trailer + .productPage-trailer {
        margin-top: 7.8125vw; }
      .productPage .container .productPage-view .productPage-trailer {
        position: relative;
        width: 100%;
        height: 32.5vw; }
        .productPage .container .productPage-view .productPage-trailer__text {
          position: absolute;
          left: 0;
          bottom: -7vw;
          letter-spacing: 0.39vw;
          font-size: 6.25vw;
          font-weight: 700;
          color: var(--color-light);
          opacity: 0;
          transition: 0.5s; }
          .productPage .container .productPage-view .productPage-trailer__text span {
            -webkit-text-stroke-width: 1px;
            -webkit-text-stroke-color: var(--color-light);
            color: transparent; }
        .productPage .container .productPage-view .productPage-trailer .active {
          opacity: 1;
          bottom: -4.6875vw; }
        .productPage .container .productPage-view .productPage-trailer .productPage-img {
          position: absolute;
          right: 0;
          top: 0;
          height: 100%;
          width: 50%;
          overflow: hidden; }
          .productPage .container .productPage-view .productPage-trailer .productPage-img img {
            position: absolute;
            transition: 0.5s;
            top: 0;
            left: 0;
            height: 100%;
            -o-object-fit: cover;
            object-fit: cover;
            opacity: 0;
            transform: translateX(200px);
            image-rendering: -webkit-optimize-contrast; }
          .productPage .container .productPage-view .productPage-trailer .productPage-img .active {
            opacity: 1;
            transform: translateX(0); }
    .productPage .container .productPage-imgs {
      position: relative; }
      .productPage .container .productPage-imgs::before {
        content: "";
        position: absolute;
        top: 0;
        left: 4vw;
        width: 75%;
        height: 100%;
        background: var(--color-white);
        opacity: 0.1;
        z-index: -1; }
      .productPage .container .productPage-imgs .productPage-imgs_img {
        position: relative;
        margin-top: 5vw; }
        .productPage .container .productPage-imgs .productPage-imgs_img:first-child {
          margin-top: 10vw; }
        .productPage .container .productPage-imgs .productPage-imgs_img .productPage-imgs__name {
          text-align: end;
          font-size: 2vw;
          font-weight: 700;
          color: var(--color-light);
          border-right: 0.5vw solid var(--color-light);
          padding-right: 1.5vw;
          margin-bottom: 1vw; }
    .productPage .container .productPage-button {
      position: absolute;
      width: 100%;
      bottom: 15vw;
      left: 50%;
      transform: translateX(-50%);
      text-align: center; }
      .productPage .container .productPage-button__text {
        mix-blend-mode: difference;
        z-index: 2;
        display: inline-block; }
        .productPage .container .productPage-button__text p {
          font-size: 1.5vw;
          font-weight: 500; }
        .productPage .container .productPage-button__text a i {
          display: block;
          font-size: 5.46875vw;
          font-weight: 600;
          -webkit-text-stroke-width: 1px;
          -webkit-text-stroke-color: var(--color-light);
          color: transparent;
          line-height: 5.46875vw;
          transition: 0.3s; }

@media (max-width: 1200px) {
  .productPage__title h2 {
    font-size: 6vw;
    letter-spacing: 1vw;
    margin-bottom: 1vw; }
  .productPage__title p {
    font-size: .875em;
    letter-spacing: 1vw;
    margin-left: 1vw; }
  .productPage__title .scroll-down {
    bottom: -9vw; }
    .productPage__title .scroll-down .scroll-down-bar {
      height: 5vw; }
  .productPage .container {
    padding: 35vw 0 40vh 0;
    width: 65%;
    max-width: 65% !important;
    overflow-x: hidden; }
    .productPage .container .productPage-video__iframe {
      margin-top: 15vw; }
    .productPage .container .productPage-view {
      margin-top: 5vw; }
      .productPage .container .productPage-view .productPage-info {
        position: relative;
        top: 3vw;
        width: 100%; }
        .productPage .container .productPage-view .productPage-info .productPage-decription__title h3 {
          font-size: 4vw;
          letter-spacing: 0.5vw; }
        .productPage .container .productPage-view .productPage-info .productPage-decription__content {
          margin-top: 1.5vw; }
          .productPage .container .productPage-view .productPage-info .productPage-decription__content h4 {
            font-size: 2.2vw;
            line-height: 3.5vw; }
        .productPage .container .productPage-view .productPage-info .productPage-more {
          margin-top: 0.78vw;
          flex-direction: column;
          align-items: start !important; }
          .productPage .container .productPage-view .productPage-info .productPage-more .productPage-details {
            margin-right: 4vw;
            width: 100%; }
            .productPage .container .productPage-view .productPage-info .productPage-more .productPage-details .productPage-detail {
              margin-top: 2.5vw; }
              .productPage .container .productPage-view .productPage-info .productPage-more .productPage-details .productPage-detail__title {
                font-size: 4vw; }
              .productPage .container .productPage-view .productPage-info .productPage-more .productPage-details .productPage-detail__content {
                margin-top: 1.3vw;
                font-size: 2.2vw; }
                .productPage .container .productPage-view .productPage-info .productPage-more .productPage-details .productPage-detail__content a svg {
                  width: 2vw;
                  margin-left: 1.5vw; }
                .productPage .container .productPage-view .productPage-info .productPage-more .productPage-details .productPage-detail__content a:hover svg {
                  margin-left: 2vw; }
      .productPage .container .productPage-view .productPage-trailer + .productPage-trailer {
        margin-top: 7.8125vw; }
      .productPage .container .productPage-view .productPage-trailer {
        height: 35.5vw;
        margin-top: 10vw; }
        .productPage .container .productPage-view .productPage-trailer__text {
          bottom: -7vw;
          font-size: 6vw;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis; }
        .productPage .container .productPage-view .productPage-trailer .active {
          bottom: -10vw; }
        .productPage .container .productPage-view .productPage-trailer .productPage-img {
          position: relative;
          width: 100%; }
    .productPage .container .productPage-imgs::before {
      opacity: 0.1;
      z-index: 0; }
    .productPage .container .productPage-imgs .productPage-imgs_img {
      margin-top: 5vw; }
      .productPage .container .productPage-imgs .productPage-imgs_img:first-child {
        margin-top: 15vw; }
      .productPage .container .productPage-imgs .productPage-imgs_img .productPage-imgs__name {
        margin-bottom: 1.5vw; }
    .productPage .container .productPage-button {
      bottom: 30vw; }
      .productPage .container .productPage-button__text {
        transform: none !important; }
        .productPage .container .productPage-button__text p {
          font-size: 4vw; }
        .productPage .container .productPage-button__text a i {
          font-size: 7vw;
          line-height: 8vw; } }

@media (max-width: 400px) {
  .productPage .container {
    padding: 60vw 0 40vh 0; } }
