body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
:root{
  --color-white:#ffffff;
  --color-black:#000000;
  --color-light:#eaeaea;
  --color-dark:#1a1a1a;
}
body.light-theme{
  --color-white:#000000;
  --color-black:#ffffff;
  --color-light:#1a1a1a;
  --color-dark:#eaeaea;
  transition: 0.5s;
  animation: opacity 1.5s ease;
}

